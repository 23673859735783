import 'firebase/auth';
import React from 'react';
import Swal from 'sweetalert';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import Header from '../../admin/components/Header';
import { ROLES, roles } from '../../admin/constants/Constants';
import { getProductsById, getQuoteById, updateQuoteById, updateProductById, getLastApprovedPrice } from '../../services';
import moment from 'moment';

class AdminQuoteEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: [],
      comment: '',
      asked_price: 0,
      limit_price: 0,
      approved_price: 0,
      approved_price_comment: '',
      average_selling_price: 0,
      last_approved_price: 0,
      last_approved_date: 0,
      price_increase: 0
    };
  }

  async componentDidMount() {
    await this.getQuoteDetails();
    await this.getProductDetails();
  }

  calculateGrandTotal = (productList) => {
    const subTotal = productList && productList.reduce((subTotal, product) => subTotal + product.sub_total, 0);
    const totalForTax = 0 + subTotal;
    const tax = (12 / 100) * totalForTax;
    const total = subTotal + 0 + tax;
    return {
      sub_total: subTotal,
      freight: 0,
      tax: tax,
      total: total,
    };
  }

  getProductDetails = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { dispatch, history } = this.props;
    await this.setState({
      loading: true,
    });
    const response = await getProductsById(id);
    if (response) {
      if (response && response.status === 200) {
        const product = response.data;
        await this.setState({
          limit_price: product[0].limit_price ? `${product[0].limit_price}` : 0,
          average_selling_price: product[0].average_selling_price ? `${product[0].average_selling_price}` : 0,
          price_increase: product[0]?.price_increase ? `${product[0]?.price_increase}` : 0,
          loading: false,
        });
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
          await dispatch({ type: 'USER', payload: {} });
          await localStorage.removeItem('token');
          history.push('/admin');
        }
        this.setState({ loading: false });
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  getLastPrice = async (productId, userId) => {
    const { dispatch, history } = this.props;
    await this.setState({
      loading: true,
    });
    try {
      const response = await getLastApprovedPrice(productId, userId)
      if (response) {
        if (response.status === 200) {
          const { approved_price, approved_date } = response.data;
          this.setState({
            last_approved_price: approved_price,
            last_approved_date: approved_date,
            loading: false,
          });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            dispatch({ type: 'USER', payload: {} });
            localStorage.removeItem('token');
            history.push('/admin');
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  }

  getQuoteDetails = async () => {
    const {
      match: {
        params: { id, quoteId },
      },
      dispatch,
      history,
    } = this.props;
    await this.setState({
      loading: true,
    });
    try {
      const response = await getQuoteById(quoteId);
      if (response) {
        if (response.status === 200) {
          const quoteProducts = response.data.products;
          const product = (quoteProducts && quoteProducts.find((item) => item.id === id)) || {};
          await this.getLastPrice(product.id, product.userId)
          await this.setState({
            quote: product,
            comment: product?.comment ? product?.comment : "",
            asked_price: product?.asked_price ? product?.asked_price?.toFixed(2) : 0,
            approved_price: product?.approved_price ? product?.approved_price?.toFixed(2) : 0,
            approved_price_comment: product?.approved_price_comment ? product?.approved_price_comment : "",
            // limit_price: product.limit_price ? `${product.limit_price}` : 0,
            loading: false,
          });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  };

  onApprove = async (event) => {
    const { asked_price, comment, approved_price, approved_price_comment } = this.state;
    const {
      match: {
        params: { id, quoteId },
      },
      dispatch,
      history,
    } = this.props;
    event.preventDefault();
    const result = await getQuoteById(quoteId);
    let productList = result && result.data.products;
    const ProductIndex = productList.findIndex((item) => item.id === id);
    const product = productList.find((item) => item.id === id);
    const price = Number(approved_price) ? Number(approved_price) : Number(asked_price)
    product.asked_price = Number(asked_price);
    product.approved_price = Number(approved_price)
    product.comment = comment;
    product.approved_price_comment = approved_price_comment
    product.sub_total = Number(price) > 0 ? Number(price) * product.quantity : product.discounted_price > 0 ? product.discounted_price * product.quantity : product.price * product.quantity;
    product.approved = true;
    product.action_taken = true;
    productList[ProductIndex] = product;
    const { sub_total, freight, tax, total } = this.calculateGrandTotal(productList)
    const updateProductData = {
      products: productList,
      sub_total,
      freight,
      tax,
      total
    };
    const response = await updateQuoteById(quoteId, updateProductData);
    try {
      if (response) {
        if (response.status === 200 || response.status === 201) {
          this.setState({ loading: false });
          // await this.updateGrandTotal();
          toast.success('Your product has been approved');
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
    this.props.history.push(`/admin/quotes/${quoteId}`);
  };

  onReject = async (event) => {
    const { comment } = this.state;
    const {
      match: {
        params: { id, quoteId },
      },
      dispatch,
      history,
    } = this.props;
    event.preventDefault();
    Swal({
      title: `Confirm`,
      text: ` Are you sure you want to reject? You will not able to un-do the changes once confirmed.`,
      icon: 'error',
      dangerMode: true,
      buttons: ['Cancel', 'Yes'],
      modal: false,
    }).then(async (status) => {
      if (status) {
        const result = await getQuoteById(quoteId);
        let productList = result && result.data.products;
        const ProductIndex = productList.findIndex((item) => item.id === id);
        const product = productList.find((item) => item.id === id);
        product.asked_price = 0;
        product.approved_price = 0;
        product.comment = comment;
        product.sub_total = product.discounted_price > 0 ? product.discounted_price * product.quantity : product.price * product.quantity;
        product.approved = false;
        product.action_taken = true;
        productList[ProductIndex] = product;
        const { sub_total, freight, tax, total } = this.calculateGrandTotal(productList)
        const updateQuoteData = {
          products: productList,
          sub_total,
          freight,
          tax,
          total
        };
        const response = await updateQuoteById(quoteId, updateQuoteData);
        try {
          if (response) {
            if (response.status === 200 || response.status === 201) {
              this.setState({ loading: false });
              // await this.updateGrandTotal();
              toast.success('Your product has been rejected');
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                await dispatch({ type: 'USER', payload: {} });
                await localStorage.removeItem('token');
                history.push('/admin');
              }
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
        this.props.history.push(`/admin/quotes/${quoteId}`);
      }
    });
  };

  onCommentChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
    if (value.length > 200) {
      document.getElementById('commentLengthError').innerHTML = 'Maximum 200 characters are allowed';
    } else {
      document.getElementById('commentLengthError').innerHTML = '';
    }
  };

  onSaveApprovedPrice = async (event) => {
    const { asked_price, approved_price, approved_price_comment, comment } = this.state;
    try {
      const {
        match: {
          params: { id, quoteId },
        },
        dispatch,
        history,
      } = this.props;
      this.setState({
        loading: true,
      });
      event.preventDefault();
      const result = await getQuoteById(quoteId);
      let productList = result && result.data.products;
      const ProductIndex = productList.findIndex((item) => item.id === id);
      const product = productList.find((item) => item.id === id);
      const price = Number(approved_price) ? Number(approved_price) : Number(asked_price)
      product.comment = comment;
      product.approved_price = Number(approved_price);
      product.approved_price_comment = approved_price_comment
      product.sub_total = Number(price) > 0 ? Number(price) * product.quantity : product.discounted_price > 0 ? product.discounted_price * product.quantity : product.price * product.quantity;
      productList[ProductIndex] = product;
      const { sub_total, freight, tax, total } = this.calculateGrandTotal(productList)

      const updateQuoteData = {
        products: productList,
        sub_total,
        freight,
        tax,
        total,
      };
      const response = await updateQuoteById(quoteId, updateQuoteData);

      if (response) {
        if (response.status === 200 || response.status === 201) {
          this.setState({ loading: false });
          // await this.updateGrandTotal();
          toast.success('Your product has been updated');
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }

    } catch (error) {
      console.log(error)
    }
  }

  onSave = async (event) => {
    const { asked_price, limit_price, comment, approved_price } = this.state;
    const {
      match: {
        params: { id, quoteId },
      },
      dispatch,
      history,
    } = this.props;
    event.preventDefault();
    const result = await getQuoteById(quoteId);
    let productList = result && result.data.products;
    const ProductIndex = productList.findIndex((item) => item.id === id);
    const product = productList.find((item) => item.id === id);
    const price = Number(approved_price) ? Number(approved_price) : Number(asked_price)
    product.limit_price = Number(limit_price);
    // product.asked_price = Number(asked_price);
    product.approved_price = Number(approved_price);
    product.comment = comment;
    product.sub_total = Number(price) > 0 ? Number(price) * product.quantity : product.discounted_price > 0 ? product.discounted_price * product.quantity : product.price * product.quantity;
    productList[ProductIndex] = product;
    const updateProductData = {
      products: productList,
    };
    const response = await updateQuoteById(quoteId, updateProductData);
    try {
      if (response) {
        if (response.status === 200 || response.status === 201) {
          this.setState({ loading: false });
          await this.updateProductList();
          await this.updateGrandTotal();
          toast.success('Your product has been updated');
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  updateGrandTotal = async () => {
    const {
      match: {
        params: { quoteId },
      },
      dispatch,
      history,
    } = this.props;
    const result = await getQuoteById(quoteId);
    // const quoteData = result && result.data;
    const productList = result && result.data.products;
    const subTotal = productList && productList.reduce((subTotal, product) => subTotal + product.sub_total, 0);
    // const freight = quoteData && quoteData.request_type === 'Delivery' ? (0.5 / 100) * subTotal : quoteData && quoteData.request_type === 'Pick-Up' ? (0.15 / 100) * subTotal : 0;
    // const totalForTax = freight + subTotal;
    // const tax = (12 / 100) * totalForTax;
    // const total = subTotal + freight + tax;
    const totalForTax = 0 + subTotal;
    const tax = (12 / 100) * totalForTax;
    const total = subTotal + 0 + tax;
    const updateQuoteData = {
      sub_total: subTotal,
      freight: 0,
      tax: tax,
      total: total,
    };
    const response = await updateQuoteById(quoteId, updateQuoteData);
    try {
      if (response) {
        if (response.status === 200 || response.status === 201) {
          this.setState({ loading: false });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  onSaveLimitPrice = async (event) => {
    const {
      match: {
        params: { id },
      },
      dispatch,
      history,
    } = this.props;
    this.setState({
      loading: true,
    });
    const { limit_price } = this.state;
    const updateProductData = {
      limit_price: Number(limit_price),
    };
    event.preventDefault();
    const response = await updateProductById(id, updateProductData);
    try {
      if (response) {
        if (response.status === 200 || response.status === 201) {
          this.setState({ loading: false });
          toast.success('Your product has been updated');
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };


  onInputChange = ({ target: { name, value } }) => {
    if (!isNaN(Number(value))) {
      this.setState({ [name]: value });
    }
  };

  render() {
    const {
      user,
      match: {
        params: { id, quoteId },
      },
    } = this.props;
    const { quote, limit_price, asked_price, loading, comment, approved_price, average_selling_price, last_approved_price, last_approved_date, approved_price_comment, price_increase } = this.state;
    const product = quote;
    const price = product && Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(product.price);
    const DiscountedPrice = product && Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(product.discounted_price);
    let finalPrice = Number(asked_price) > 0 ? Number(asked_price) * product?.quantity : product?.discounted_price > 0 ? product?.discounted_price * product.quantity : product.price * product.quantity;
    finalPrice = Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(finalPrice);

    return (
      <>
        <div className="container mt-2">
          <Spinner display={loading}>
            <Header section={`Overview of product ${id}`} title={product?.name || ''}>
              <Link to={`/admin/quotes/${quoteId}`} className="btn btn-outline-secondary">
                Cancel
              </Link>
              {!user.specialRole && (Number(limit_price) >= 0 || Number(approved_price) < Number(limit_price)) && user?.hasOwnProperty(ROLES) && user.roles.includes(roles?.SUPER_ADMIN_USER) && (
                <button type="button" className="btn btn-success ml-2" onClick={this.onApprove}>
                  Approve
                </button>
              )}
              {!user.specialRole && Number(limit_price) > 0 && Number(approved_price) >= Number(limit_price) && user?.hasOwnProperty(ROLES) && user.roles.includes(roles?.ADMIN_USER) && (
                <button type="button" className="btn btn-success ml-2" onClick={this.onApprove}>
                  Approve
                </button>
              )}
              {!user.specialRole && (
                <button type="button" className="btn btn-merck-pink ml-2" onClick={this.onReject}>
                  Reject
                </button>
              )}
            </Header>
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {!user.specialRole && Number(asked_price) < Number(limit_price) && user.hasOwnProperty(ROLES) && user.roles.includes(roles.ADMIN_USER) && <i className="text-merck-purple">Note: Endorse Super-admin since Asked Price is less than the Limit Price</i>}
                {!user.specialRole && Number(asked_price) > 0 && Number(limit_price) <= 0 && user.hasOwnProperty(ROLES) && user.roles.includes(roles.ADMIN_USER) && <i className="text-merck-purple">Note: Endorse Super-admin since Limit Price is not available</i>}
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label>List Price</label>
                            <input disabled className="form-control" value={price} style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Discounted Price</label>
                            <input disabled className="form-control" value={DiscountedPrice} style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>SubTotal</label>
                            <input disabled className="form-control" value={finalPrice} style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Average Selling Price</label>
                            <input disabled maxLength={15} id="average_selling_price" name="average_selling_price" value={average_selling_price ? Number(average_selling_price).toFixed(2) : ''} className="form-control" style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Last Approved Price</label>
                            <input disabled maxLength={15} id="last_approved_price" name="last_approved_price" value={Number(last_approved_price) || ''} className="form-control" style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Last Approved Date:</label>
                            <input disabled maxLength={15} id="last_approved_date" name="last_approved_date" value={last_approved_date ? moment(last_approved_date).format('ll') : null} className="form-control" style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>% Price Increase</label>
                            <input disabled maxLength={15} id="price_increase" name="price_increase" value={Number(price_increase) || ''} className="form-control" style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Asked Price (Per Quantity)</label>
                            <input disabled maxLength={15} id="asked_price" name="asked_price" value={Number(asked_price) || ''} className="form-control" placeholder="e.g. 2000" style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Limit Price</label>
                            <input id="limit_price" name="limit_price" disabled={user.hasOwnProperty(ROLES) && user.roles.includes(roles.ADMIN_USER)} value={Number(limit_price) || ''} onChange={this.onInputChange} className="form-control" style={{ backgroundColor: user.hasOwnProperty(ROLES) && user.roles.includes(roles.ADMIN_USER) ? '#a3dce2' : 'none' }} />
                          </div>
                          <div className="d-flex justify-content-end">
                            {user.hasOwnProperty(ROLES) && user.roles.includes(roles.SUPER_ADMIN_USER) && (
                              <button type="button" className="btn btn-sm btn-info" onClick={this.onSaveLimitPrice}>
                                Save Limit Price
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label>Approval Price</label>
                            <input maxLength={15} id="approved_price" name="approved_price" value={Number(approved_price) || ''} className="form-control" placeholder="e.g. 2000" onChange={this.onInputChange} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Approval Price Comment</label>
                            <textarea type="text" name="approved_price_comment" className="form-control" placeholder="Please enter reason for approved price comment" value={approved_price_comment || ''} onChange={this.onCommentChange} multiple />
                            <span id="commentLengthError" className="error-text text-danger" />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Comment</label>
                            <textarea type="text" name="comment" className="form-control" placeholder="Please enter reason for rejecting/approving special discount" value={comment} onChange={this.onCommentChange} multiple />
                            <span id="commentLengthError" className="error-text text-danger" />
                          </div>
                          <div className="d-flex justify-content-end">
                            {user.hasOwnProperty(ROLES) && user.roles.includes(roles.ADMIN_USER) && (
                              <button type="button" className="btn btn-sm btn-info" onClick={this.onSaveApprovedPrice}>
                                Save Approved Price
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Reason</label>
                            {/* <input className="form-control" disabled value={product?.reason || ''} style={{ backgroundColor: '#F1F2F3' }} /> */}
                            <textarea type="text" name="reason" className="form-control" disabled value={product?.reason || ''} multiple style={{ backgroundColor: '#F1F2F3' }} rows={3} />
                          </div>
                        </div>
                      </div>
                    </form>
                    {!user.specialRole && user.hasOwnProperty(ROLES) && user.roles.includes(roles.SUPER_ADMIN_USER) && <i className="text-merck-purple">Note: Please Update the Limit Price and Save</i>}
                  </div>
                </div>
              </div>
            </div>
          </Spinner>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    distributor: state.distributor,
  };
};

export default connect(mapStateToProps)(AdminQuoteEdit);
